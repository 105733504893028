// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bB_kC";
export var caseStudyBackground__lineColor = "bB_kz";
export var caseStudyCta__bgColor = "bB_kP";
export var caseStudyHead__imageWrapper = "bB_kv";
export var caseStudyProjectsSection = "bB_kD";
export var caseStudyQuote__bgDark = "bB_lq";
export var caseStudyQuote__bgLight = "bB_kK";
export var caseStudyQuote__bgRing = "bB_kx";
export var caseStudySolution__ring = "bB_kH";
export var caseStudySolution__ringThree = "bB_lr";
export var caseStudySolution__ringTwo = "bB_kJ";
export var caseStudyVideo = "bB_kM";
export var caseStudyVideo__ring = "bB_kN";
export var caseStudy__bgDark = "bB_ks";
export var caseStudy__bgLight = "bB_kr";